<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="610px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="ma-0 d-flex justify-space-between">
        <span
          class="font-weight-bold primary--text"
          :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'text-h5': $vuetify.breakpoint.xsOnly,}"
        >
          Gestión de Plantilla
        </span>
        <v-btn icon plain @click="cerrar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay
        :value="loadingAction || loading"
        color="white"
        absolute
        opacity=".8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <validation-observer ref="TEMPLATE_FORM" tag="div">
          <v-row>
            <v-col cols="12" sm="7" md="8" lg="8" class="pt-2 pb-0">
              <label-form text="Nombre" required />
              <validation-provider name="Nombre" vid="templateInfo.nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                  :value="data.nombre"
                  v-model="templateInfo.nombre"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4" class="pt-2 pb-0">
              <label-form text="Código" required/>
              <validation-provider name="codigo" vid="templateInfo.codigo" rules="required" v-slot="{ errors }">
                <v-text-field
                  :value="data.codigo"
                  v-model="templateInfo.codigo"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <label-form text="Descripción" required/>
              <validation-provider name="Descripcion" vid="templateInfo.descripcion" rules="required" v-slot="{ errors }">
                <v-textarea
                  :value="data.descripcion"
                  v-model="templateInfo.descripcion"
                  outlined
                  dense
                  rows="2"
                  :error-messages="errors[0]"
                />
               </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex flex-column">
              <label-form text="Mensaje" required/>
               <validation-provider name="Mensaje" vid="templateInfo.contenido" rules="required" v-slot="{ errors }">
                <v-textarea
                  v-model="templateInfo.contenido"
                  rows="3"
                  placeholder="Escribe su Mensaje"
                  :counter="140"
                  outlined
                  :error-messages="errors[0]"
                  :rules="validate"
                  />
               </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mb-0 mt-4">
              <v-list
                three-line
                class="pa-0 ma-0"
              >
                <v-list-item class="pa-0 ma-0">
                  <v-list-item-action class="">
                    <v-switch
                      v-model="templateInfo.status"
                      color="secondary"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-content class="align-self-start">
                    <v-list-item-title class="display font-weight-bold">Habilitar Plantilla</v-list-item-title>
                    <v-list-item-subtitle class="mt-2">Opción para habilitar o deshabilitar un Plantilla.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mb-3">
        <v-btn
          tile
          depressed
          color="blue-grey lighten-5"
          class="px-8 mx-2"
          :small="$vuetify.breakpoint.mobile"
          @click="cerrar()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          depressed
          color="primary"
          class="px-8"
          :small="$vuetify.breakpoint.mobile"
          @click="actionGroup"
        >
          {{action === 'editar' ? 'Actualizar' : 'Crear'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { saveTemplate, getTemplate } from '../../../../services/mensajeria'
const dataDefault = () => ({
  nombre: '',
  codigo: '',
  descripcion: '',
  contenido: '',
  status: true,
});
export default {
  name:'ModalPlantilla',
  props:{
    value: Boolean,
    data:{
      type: Object,
      default: () => ({}),
    },
    loading:{
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'crear',
    },
  },
  data() {
    return {
      show: this.value,
      loadingAction: false,
      loadClients: false,
      templateInfo: dataDefault(),
      validate: [v => {
        if(!v) return true
        return v.length <= 140 || 'Máximo 140 caracteres'
      }],
    }
  },
  watch: {
    show (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.show = val
    },
    data(val) {
      if(Object.values(val).length > 0) {
        this.templateInfo = {...val, status: Boolean(val.status) }
        // this.getDataTemplate(val?.id)
      }
      else
        this.templateInfo = dataDefault();
    },
    selectCli (val, oldVal) {
      this.clientesSelected.push({ ...val })
    },
  },
  filters: {
    fisrtLetter(val) {
      return val !== null ? val.toUpperCase().charAt(0) : ''
    }
  },
  created () {
    // this.getDataTemplate()
  },
  methods: {
    cerrar() {
      this.show = false;
      this.templateInfo = dataDefault();
      this.$refs.TEMPLATE_FORM.reset();
    },
    async actionGroup() {
      const valid = await this.$refs.TEMPLATE_FORM.validate();
      if(valid) {
        try {
          this.loadingAction = true;
          const { message } = await saveTemplate({
            info: this.templateInfo,
            method: this.action === 'crear' ? 'post' : 'put',
            idTemplate: this.templateInfo?.id
          })
          this.$emit('procesado', true);
          this.cerrar();
          this.$root.$showAlert(message, 'success');
        } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error'
            );
        } finally {
          this.loadingAction = false;
        }
      }
    },

    async getDataTemplate(idTemplate){
      this.loadingAction = true;
      try {
        const {data} = await getTemplate({ idTemplate })
        this.templateInfo = {...data, contenido: data?.descripcion}
        console.log({data})
      } catch (error) {
        this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error'
            );
      }
    },
  },
}
</script>
<style>
.list-clients .v-data-footer__select {
  display: none;
}
</style>
