var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"610px","scrollable":"","content-class":"content-scroll"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"ma-0 d-flex justify-space-between"},[_c('span',{staticClass:"font-weight-bold primary--text",class:{'text-h4': _vm.$vuetify.breakpoint.smAndUp, 'text-h5': _vm.$vuetify.breakpoint.xsOnly,}},[_vm._v(" Gestión de Plantilla ")]),_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.cerrar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loadingAction || _vm.loading,"color":"white","absolute":"","opacity":".8"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('v-card-text',[_c('validation-observer',{ref:"TEMPLATE_FORM",attrs:{"tag":"div"}},[_c('v-row',[_c('v-col',{staticClass:"pt-2 pb-0",attrs:{"cols":"12","sm":"7","md":"8","lg":"8"}},[_c('label-form',{attrs:{"text":"Nombre","required":""}}),_c('validation-provider',{attrs:{"name":"Nombre","vid":"templateInfo.nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.data.nombre,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.templateInfo.nombre),callback:function ($$v) {_vm.$set(_vm.templateInfo, "nombre", $$v)},expression:"templateInfo.nombre"}})]}}])})],1),_c('v-col',{staticClass:"pt-2 pb-0",attrs:{"cols":"12","sm":"5","md":"4","lg":"4"}},[_c('label-form',{attrs:{"text":"Código","required":""}}),_c('validation-provider',{attrs:{"name":"codigo","vid":"templateInfo.codigo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.data.codigo,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.templateInfo.codigo),callback:function ($$v) {_vm.$set(_vm.templateInfo, "codigo", $$v)},expression:"templateInfo.codigo"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label-form',{attrs:{"text":"Descripción","required":""}}),_c('validation-provider',{attrs:{"name":"Descripcion","vid":"templateInfo.descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"value":_vm.data.descripcion,"outlined":"","dense":"","rows":"2","error-messages":errors[0]},model:{value:(_vm.templateInfo.descripcion),callback:function ($$v) {_vm.$set(_vm.templateInfo, "descripcion", $$v)},expression:"templateInfo.descripcion"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('label-form',{attrs:{"text":"Mensaje","required":""}}),_c('validation-provider',{attrs:{"name":"Mensaje","vid":"templateInfo.contenido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"3","placeholder":"Escribe su Mensaje","counter":140,"outlined":"","error-messages":errors[0],"rules":_vm.validate},model:{value:(_vm.templateInfo.contenido),callback:function ($$v) {_vm.$set(_vm.templateInfo, "contenido", $$v)},expression:"templateInfo.contenido"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-0 mt-4",attrs:{"cols":"12"}},[_c('v-list',{staticClass:"pa-0 ma-0",attrs:{"three-line":""}},[_c('v-list-item',{staticClass:"pa-0 ma-0"},[_c('v-list-item-action',{},[_c('v-switch',{attrs:{"color":"secondary","hide-details":""},model:{value:(_vm.templateInfo.status),callback:function ($$v) {_vm.$set(_vm.templateInfo, "status", $$v)},expression:"templateInfo.status"}})],1),_c('v-list-item-content',{staticClass:"align-self-start"},[_c('v-list-item-title',{staticClass:"display font-weight-bold"},[_vm._v("Habilitar Plantilla")]),_c('v-list-item-subtitle',{staticClass:"mt-2"},[_vm._v("Opción para habilitar o deshabilitar un Plantilla.")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{staticClass:"px-8 mx-2",attrs:{"tile":"","depressed":"","color":"blue-grey lighten-5","small":_vm.$vuetify.breakpoint.mobile},on:{"click":function($event){return _vm.cerrar()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"px-8",attrs:{"tile":"","depressed":"","color":"primary","small":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.actionGroup}},[_vm._v(" "+_vm._s(_vm.action === 'editar' ? 'Actualizar' : 'Crear')+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }